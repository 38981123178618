<template>
  <div class="row items-center justify-between">
    <PageHeader
      breadcrumb="Home"
      title="Dashboard"
      subTitle="What insights do you want to see today?"
    />

    <FormSelect
      label="Filter"
      v-model="filterDate"
      :values="filterDate"
      :options="filterOptions"
      :clearable="false"
    />
  </div>

  <PageContent>
    <div class="column q-col-gutter-md">
      <div>
        <div class="row items-start q-col-gutter-md">
          <div class="col-xs-12 col-sm-6 col-md-3">
            <DashboardCard
              class="col-xs-12 col-sm-6 col-md-3"
              variant="primary"
              title="Sales"
              :value="widgetSales.sales.toString()"
              :loading="loadingWidget"
            />
          </div>

          <div class="col-xs-12 col-sm-6 col-md-3">
            <DashboardCard
              class="col-xs-12 col-sm-6 col-md-3"
              variant="warning"
              title="Transactions"
              :value="widgetSales.trxCount.toString()"
              :loading="loadingWidget"
            />
          </div>

          <div class="col-xs-12 col-sm-6 col-md-3">
            <DashboardCard
              class="col-xs-12 col-sm-6 col-md-3"
              variant="success"
              title="Ave Basket Amount"
              :value="widgetSales.basketSize.toString()"
              :loading="loadingWidget"
            />
          </div>

          <div class="col-xs-12 col-sm-6 col-md-3">
            <DashboardCard
              class="col-xs-12 col-sm-6 col-md-3"
              variant="info"
              title="Ave Basket Item Count"
              :value="widgetSales.basketSizeItem.toString()"
              :loading="loadingWidget"
            />
          </div>
        </div>
      </div>

      <div class="row items-start q-col-gutter-md">
        <div class="col-xs-12 col-sm-12 col-md-6">
          <SimpleCard
            :header="filterDate === 'ytd' ? 'Monthly Sales' : 'Daily Sales'"
            noHeader
          >
            <LineChart
              type="DailySales"
              axis="xy"
              xData="date"
              yData="sales"
              :data="dailySales"
              v-if="dailySales?.length > 0"
            />
            <div class="noData" v-else>No data</div>
            <Loader :loading="loadingDailySales" inner />
          </SimpleCard>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-6">
          <SimpleCard
            :header="
              filterDate === 'ytd'
                ? 'Monthly Transactions'
                : 'Daily Transactions'
            "
            noHeader
          >
            <LineChart
              type="DailyTransactions"
              axis="xy"
              xData="date"
              yData="sales"
              :data="dailyTransactions"
              v-if="dailyTransactions?.length > 0"
            />
            <div class="noData" v-else>No data</div>
            <Loader :loading="loadingDailySales" inner />
          </SimpleCard>
        </div>
      </div>

      <div class="row items-start q-col-gutter-md">
        <div class="col-xs-12 col-sm-12 col-md-6">
          <SimpleCard header="Top 5 Branches" noHeader>
            <HorizontalBarChart
              :style="{ minHeight: '350px' }"
              type="TopBranches"
              axis="xy"
              xData="sales"
              yData="store"
              :data="topBranches"
              v-if="topBranches?.length > 0"
            />
            <div class="noData" v-else>No data</div>
            <Loader :loading="loadingTopBranches" inner />
          </SimpleCard>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-6">
          <SimpleCard header="Top 5 SKUs" noHeader>
            <HorizontalBarChart
              :style="{ minHeight: '350px' }"
              type="TopSKUs"
              axis="xy"
              xData="sales"
              yData="sku"
              :data="topSKUs"
              v-if="topSKUs?.length > 0"
            />
            <div class="noData" v-else>No data</div>
            <Loader :loading="loadingTopSKUs" inner />
          </SimpleCard>
        </div>
      </div>

      <div class="row items-start q-col-gutter-md">
        <div class="col-xs-12 col-sm-12 col-md-6">
          <SimpleCard header="Top 5 Vendors" noHeader>
            <HorizontalBarChart
              :style="{ minHeight: '350px' }"
              type="TopVendors"
              axis="xy"
              xData="sales"
              yData="vendor"
              :data="topVendors"
              v-if="topVendors?.length > 0"
            />
            <div class="noData" v-else>No data</div>
            <Loader :loading="loadingTopVendors" inner />
          </SimpleCard>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-6">
          <SimpleCard header="Top 5 Categories" noHeader>
            <HorizontalBarChart
              :style="{ minHeight: '350px' }"
              type="TopCategories"
              axis="xy"
              xData="sales"
              yData="category"
              :data="topCategories"
              v-if="topCategories?.length > 0"
            />
            <div class="noData" v-else>No data</div>
            <Loader :loading="loadingTopCategories" inner />
          </SimpleCard>
        </div>
      </div>

      <div class="row items-start q-col-gutter-md">
        <div class="col-xs-12 col-sm-12 col-md-6">
          <SimpleCard header="Top 5 Brands" noHeader>
            <HorizontalBarChart
              :style="{ minHeight: '350px' }"
              type="TopBrands"
              axis="xy"
              xData="sales"
              yData="brand"
              :data="topBrands"
              v-if="topBrands?.length > 0"
            />
            <div class="noData" v-else>No data</div>
            <Loader :loading="loadingTopBrands" inner />
          </SimpleCard>
        </div>
      </div>
    </div>

    <div class="mb-32" />
  </PageContent>
</template>

<script>
import { inject, ref, watch, onBeforeMount } from 'vue'

import PageHeader from '@/components/PageHeader'
import PageContent from '@/components/PageContent'
import { DashboardCard, SimpleCard } from '@/components/cards'
import { FormSelect } from '@/components/inputs'
import { LineChart, HorizontalBarChart } from '@/components/charts'
import Loader from '@/components/Loader'

import { fetchData, DATE, FORMAT } from '@/tools'

export default {
  name: 'Dashboard',
  components: {
    PageHeader,
    PageContent,
    DashboardCard,
    SimpleCard,
    FormSelect,
    LineChart,
    HorizontalBarChart,
    Loader
  },
  data() {
    return {}
  },
  setup() {
    const state = inject('store')?.state

    const {
      data: dataWidget,
      error: errorWidget,
      loading: loadingWidget,
      post: postWidget
    } = fetchData()

    const {
      data: dataDailySales,
      error: errorDailySales,
      loading: loadingDailySales,
      post: postDailySales
    } = fetchData()

    const {
      data: dataMonthlySales,
      error: errorMonthlySales,
      loading: loadingMonthlySales,
      post: postMonthlySales
    } = fetchData()

    const {
      data: dataTopBranches,
      error: errorTopBranches,
      loading: loadingTopBranches,
      post: postTopBranches
    } = fetchData()

    const {
      data: dataTopSKUs,
      error: errorTopSKUs,
      loading: loadingTopSKUs,
      post: postTopSKUs
    } = fetchData()

    const {
      data: dataTopVendors,
      error: errorTopVendors,
      loading: loadingTopVendors,
      post: postTopVendors
    } = fetchData()

    const {
      data: dataTopCategories,
      error: errorTopCategories,
      loading: loadingTopCategories,
      post: postTopCategories
    } = fetchData()

    const {
      data: dataTopBrands,
      error: errorTopBrands,
      loading: loadingTopBrands,
      post: postTopBrands
    } = fetchData()

    const filterDate = ref(7)
    const filterOptions = [
      {
        label: 'Yesterday',
        value: 1
      },
      {
        label: 'Past 7 days',
        value: 7
      },
      {
        label: 'Past 30 days',
        value: 30
      },
      {
        label: 'Month to Date',
        value: 'mtd'
      },
      {
        label: 'Year to Date',
        value: 'ytd'
      }
    ]

    const widgetSales = ref({
      sales: 0,
      trxCount: 0,
      basketSize: 0,
      basketSizeItem: 0
    })
    const dailySales = ref([])
    const dailyTransactions = ref([])
    const topBranches = ref([])
    const topSKUs = ref([])
    const topVendors = ref([])
    const topCategories = ref([])
    const topBrands = ref([])

    const getFilterDate = payload => {
      if (filterDate.value === 1) {
        payload.filters.date_to = DATE.toFriendlyDate(
          DATE.subtractDays(new Date(), filterDate.value)
        )
      } else if (filterDate.value === 'mtd') {
        payload.filters.date_from = DATE.toFriendlyFirstDayMonth(new Date())
      } else if (filterDate.value === 'ytd') {
        payload.filters.date_from = DATE.toFriendlyFirstDayYear(new Date())
      }
    }

    const getWidgetSales = () => {
      const payload = {
        filters: {
          date_from: DATE.toFriendlyDate(
            DATE.subtractDays(new Date(), filterDate.value)
          ),
          date_to: DATE.toFriendlyDate(new Date()),
          stores: state?.profile?.store_access?.map(item => item.id)
        }
      }

      getFilterDate(payload)

      postWidget('v1/sales/daily-sales/summary', payload)
    }

    const getDailySales = () => {
      if (filterDate.value === 'ytd') {
        const payload = {
          filters: {
            month_from: DATE.toFriendlyDate(
              DATE.toFriendlyFirstDayYear(new Date(), filterDate.value)
            ),
            month_to: DATE.toFriendlyDate(
              DATE.toFriendlyFirstDayMonth(new Date())
            ),
            stores: state?.profile?.store_access?.map(item => item.id)
          }
        }

        postMonthlySales('v1/sales/monthly-sales/range', payload)
      } else {
        const payload = {
          filters: {
            date_from: DATE.toFriendlyDate(
              DATE.subtractDays(new Date(), filterDate.value)
            ),
            date_to: DATE.toFriendlyDate(new Date()),
            stores: state?.profile?.store_access?.map(item => item.id)
          }
        }

        getFilterDate(payload)
        postDailySales('v1/sales/daily-sales/detailed', payload)
      }
    }

    const getTopBranches = () => {
      const payload = {
        filters: {
          date_from: DATE.toFriendlyDate(
            DATE.subtractDays(new Date(), filterDate.value)
          ),
          date_to: DATE.toFriendlyDate(new Date()),
          stores: state?.profile?.store_access?.map(item => item.id),
          limit: 5
        }
      }

      getFilterDate(payload)

      postTopBranches('v1/sales/daily-sales/by-store', payload)
    }

    const getTopSKUs = () => {
      const payload = {
        pagination: {
          per_page: 5
        },
        filters: {
          date_from: DATE.toFriendlyDate(
            DATE.subtractDays(new Date(), filterDate.value)
          ),
          date_to: DATE.toFriendlyDate(new Date()),
          stores: state?.profile?.store_access?.map(item => item.id),
          sort_by: 'net_sales'
        }
      }

      getFilterDate(payload)

      postTopSKUs('v1/product/analysis/detailed', payload)
    }

    const getTopVendors = () => {
      const payload = {
        filters: {
          date_from: DATE.toFriendlyDate(
            DATE.subtractDays(new Date(), filterDate.value)
          ),
          date_to: DATE.toFriendlyDate(new Date()),
          stores: state?.profile?.store_access?.map(item => item.id),
          sort_by: 'net_sales',
          limit: 5
        }
      }

      getFilterDate(payload)

      postTopVendors('v1/product/by-vendor-report/daily', payload)
    }

    const getTopCategories = () => {
      const payload = {
        filters: {
          date_from: DATE.toFriendlyDate(
            DATE.subtractDays(new Date(), filterDate.value)
          ),
          date_to: DATE.toFriendlyDate(new Date()),
          stores: state?.profile?.store_access?.map(item => item.id),
          sort_by: 'net_sales',
          limit: 5
        }
      }

      getFilterDate(payload)

      postTopCategories('v1/product/by-category-report/daily', payload)
    }

    const getTopBrands = () => {
      const payload = {
        filters: {
          date_from: DATE.toFriendlyDate(
            DATE.subtractDays(new Date(), filterDate.value)
          ),
          date_to: DATE.toFriendlyDate(new Date()),
          stores: state?.profile?.store_access?.map(item => item.id),
          sort_by: 'net_sales',
          limit: 5
        }
      }

      getFilterDate(payload)

      postTopBrands('v1/product/by-brand-report/daily', payload)
    }

    const getInitialData = () => {
      getWidgetSales()
      getDailySales()
      getTopBranches()
      getTopSKUs()
      getTopVendors()
      getTopCategories()
      getTopBrands()
    }

    onBeforeMount(() => {
      getInitialData()
    })

    watch(filterDate, () => {
      getInitialData()
    })

    watch([dataWidget, errorWidget, loadingWidget], () => {
      if (loadingWidget.value) {
        widgetSales.value = {
          sales: 0,
          trxCount: 0,
          basketSize: 0,
          basketSizeItem: 0
        }
      } else {
        if (dataWidget?.value) {
          const data = dataWidget?.value[0]
          widgetSales.value = {
            sales: FORMAT.toCurrency(data.sales),
            trxCount: FORMAT.toNumber(data.trx_count),
            basketSize: FORMAT.toNumber(data.basket_size),
            basketSizeItem: FORMAT.toNumber(data.basket_size_item)
          }
        }
      }
    })

    watch([dataDailySales, errorDailySales, loadingDailySales], () => {
      if (loadingDailySales.value) {
        dailySales.value = []
        dailyTransactions.value = []
      } else {
        if (dataDailySales?.value) {
          dailySales.value = dataDailySales?.value?.map(item => ({
            date: DATE.toFriendlyDate(item?.business_date),
            sales: item?.sales
          }))

          dailyTransactions.value = dataDailySales?.value?.map(item => ({
            date: DATE.toFriendlyDate(item?.business_date),
            sales: item?.trx_count
          }))
        }
      }
    })

    watch([dataMonthlySales, errorMonthlySales, loadingMonthlySales], () => {
      if (loadingMonthlySales.value) {
        dailySales.value = []
        dailyTransactions.value = []
      } else {
        if (dataMonthlySales?.value) {
          dailySales.value = dataMonthlySales?.value?.map(item => ({
            date: DATE.toFriendlyMonthName(item?.month_year),
            sales: item?.sales
          }))

          dailyTransactions.value = dataMonthlySales?.value?.map(item => ({
            date: DATE.toFriendlyMonthName(item?.month_year),
            sales: item?.trx_count
          }))
        }
      }
    })

    watch([dataTopBranches, errorTopBranches, loadingTopBranches], () => {
      if (loadingTopBranches.value) {
        topBranches.value = []
      } else {
        if (dataTopBranches?.value) {
          topBranches.value = dataTopBranches?.value?.map(item => ({
            store: item?.store_name,
            sales: item?.sales
          }))
        }
      }
    })

    watch([dataTopSKUs, errorTopSKUs, loadingTopSKUs], () => {
      if (loadingTopSKUs.value) {
        topSKUs.value = []
      } else {
        if (dataTopSKUs?.value) {
          topSKUs.value = dataTopSKUs?.value?.data?.map(item => ({
            sku: `${item?.sku_id} - ${item?.sku_desc}`,
            sales: item?.net_sales
          }))
        }
      }
    })

    watch([dataTopVendors, errorTopVendors, loadingTopVendors], () => {
      if (loadingTopVendors.value) {
        topVendors.value = []
      } else {
        if (dataTopVendors?.value) {
          topVendors.value = dataTopVendors?.value?.map(item => ({
            vendor: item?.supplier_name ?? 'UNKNOWN VENDOR',
            sales: item?.net_sales
          }))
        }
      }
    })

    watch([dataTopCategories, errorTopCategories, loadingTopCategories], () => {
      if (loadingTopCategories.value) {
        topCategories.value = []
      } else {
        if (dataTopCategories?.value) {
          topCategories.value = dataTopCategories?.value?.map(item => ({
            category: item?.category_name ?? 'UNKNOWN CATEGORY',
            sales: item?.net_sales
          }))
        }
      }
    })

    watch([dataTopBrands, errorTopBrands, loadingTopBrands], () => {
      if (loadingTopBrands.value) {
        topBrands.value = []
      } else {
        if (dataTopBrands?.value) {
          topBrands.value = dataTopBrands?.value?.map(item => ({
            brand: item?.brand_name ?? 'UNKNOWN BRAND',
            sales: item?.net_sales
          }))
        }
      }
    })

    return {
      filterDate,
      filterOptions,
      loadingWidget,
      loadingDailySales,
      loadingTopBranches,
      loadingTopSKUs,
      loadingTopVendors,
      loadingTopCategories,
      loadingTopBrands,
      widgetSales,
      dailySales,
      dailyTransactions,
      topBranches,
      topSKUs,
      topVendors,
      topCategories,
      topBrands
    }
  }
}
</script>

<style lang="scss" scoped>
.noData {
  @apply p-4;
  @apply text-sm font-semibold;
  @apply text-center;
  color: $grey-6;
}

:deep(.q-field--outlined.q-field--readonly .q-field__control:before) {
  border-style: solid;
}

:deep(.q-date__arrow) {
  @apply hidden;
}
</style>
