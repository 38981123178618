<template>
  <Dashboard />
</template>

<script>
import Dashboard from '@/components/pages/Dashboard'

export default {
  components: {
    Dashboard
  }
}
</script>
